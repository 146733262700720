import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Navbar from "../components/Menu/LifestyleNav"
import TheaterVideo from "../components/Theater/TheaterVideo"
import ImageMeta from "../components/ImageMeta"
import { wrapSup, shuffleArray } from "../utils/utils"
import { PrivacyFooter } from "./lifestyle"

const LifestyleReviewsPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero
  } = data.allUniquePagesJson.nodes[0]

  const patients = data.patients.nodes

  shuffleArray(patients)
  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <Navbar location={location.pathname} />
      <div className="lifestyle buffer">
        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1 className="has-text-centered">{hero.heading}</h1>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="ls__section">
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
              <div className="ls__facewall">
                {patients.map((p, i) => (
                  <div key={i} className="ls__facewall-card">
                    <ImageMeta
                      useAR
                      cloudName="nuvolum"
                      publicId={p.thumbnailPublicId}
                      width="auto"
                      responsive
                    />

                    <h6>{`Meet ${p.reviewerName}`}</h6>
                    <h6
                      className="ls__facewall-procedure"
                      dangerouslySetInnerHTML={{
                        __html: wrapSup(p.mainProcedureDone)
                      }}
                    />
                    <div>
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${p.youtube}`}
                        controls
                        playing
                        buttonClass="ls__theater-btn"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="column is-2"></div>
          </div>
        </div>
      </div>
      <PrivacyFooter />
    </>
  )
}

export const lifestyleReviewsPageQuery = graphql`
  query lifestyleReviewsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        hero {
          image
          heading
        }
      }
    }
    patients: allReviewsJson(
      filter: {
        mainProcedureDone: { in: ["Dental Implants", "Full-Arch Restoration"] }
      }
    ) {
      nodes {
        reviewerName
        youtube
        thumbnailPublicId
        mainProcedureDone
      }
    }
  }
`

export default LifestyleReviewsPage
